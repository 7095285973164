<script setup lang="ts">
const social = ref([
  {
    name: 'Facebook',
    href: 'https://en-gb.facebook.com/butlersherborn/',
    icon: 'mdi:facebook',
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/butlersherborn/',
    icon: 'mdi:instagram',
  },
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/butler-sherborn',
    icon: 'mdi:linkedin',
  },
])

const propertyMenu = ref([
  {
    name: 'Search Property for Sale',
    href: '/search',
  },
  {
    name: 'Property for Rent',
    href: '/search',
  },
  {
    name: 'Market Your Property',
    href: '/property-services/sell',
  },
  {
    name: 'Equestrian Property',
    href: '/rural/equestrian-property',
  },
  {
    name: 'Land & Farms',
    href: '/rural/land-sales-acquisitions',
  },
  {
    name: 'Browse Latest Property',
    href: '/browse',
  },
])

const infoMenu = ref([
  {
    name: 'The Team',
    href: '/about',
  },
  {
    name: 'Success Stories',
    href: '/success-stories',
  },
  {
    name: 'News & Comment',
    href: '/articles',
  },
  {
    name: 'Careers',
    href: '/careers',
  },
  {
    name: 'Policies & Procedures',
    href: '/policies',
  },
  {
    name: 'Fees & Charges',
    href: '/fees',
  },
  {
    name: 'Contact Us',
    href: '/contact',
  },
])
</script>

<template>
  <div class="mt-8 mb-16">
    <!-- Logo & social -->
    <div class="space-y-8 mb-8">
      <div class="space-y-4">
        <LogoMain mode="normal" :shrink="false" />
        <div class="text-sm text-foest-50">
          The Cotswolds Property Specialist
        </div>
      </div>
      <div class="flex space-x-6">
        <nuxt-link
          v-for="item in social"
          :key="item.name"
          :href="item.href"
          class="block text-forest-50 hover:text-white"
        >
          <span class="sr-only">{{ item.name }}</span>
          <Icon :name="item.icon" class="h-7 w-7" aria-hidden="true" />
        </nuxt-link>
      </div>
    </div>

    <!-- Cotswold Property -->
    <div class="space-y-6">
      <FooterAccordion
        id="cotswold-property"
        button-label="Cotswold Property"
        class="border-t border-forest-700"
      >
        <template #detail>
          <div class="space-y-3">
            <nuxt-link
              v-for="item in propertyMenu"
              :key="item.name"
              :href="item.href"
              class="text-base font-semibold block text-forest-100 hover:text-white"
            >
              {{ item.name }}
            </nuxt-link>
          </div>
        </template>
      </FooterAccordion>
    </div>
    <!-- Info Menu -->
    <div class="space-y-6">
      <FooterAccordion
        id="information"
        button-label="Information"
        class="border-t border-forest-700"
      >
        <template #detail>
          <div class="space-y-3">
            <nuxt-link
              v-for="item in infoMenu"
              :key="item.name"
              :href="item.href"
              class="text-base font-semibold block text-forest-100 hover:text-white"
            >
              {{ item.name }}
            </nuxt-link>
          </div>
        </template>
      </FooterAccordion>
    </div>
    <!-- Our Offices -->
    <div class="space-y-6">
      <FooterAccordion
        id="our-offices"
        button-label="Our Offices"
        class="border-t border-forest-700"
      >
        <template #detail>
          <FooterBranches
            font-class="font-semibold text-base text-forest-100 hover:text-white"
          />
        </template>
      </FooterAccordion>
    </div>
  </div>
</template>
